<template>
  <DashboardView v-if="isLoggedIn"/>
  <section v-else>
    <p>This site is built with FastAPI and Vue.</p>

    <span><router-link :to="{name: 'login'}">Log In</router-link> with Speechpad account to check out all the features now!</span>
  </section>
</template>

<script>
import {defineComponent} from 'vue';
import DashboardView from "@/views/DashboardView.vue";

export default defineComponent({
  name: 'HomeView',
  components: {DashboardView},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    }
  },
});
</script>
