<template>
  <tr>
    <td class="text-center">
      <router-link :to="{name: 'job', params: {id: id}}">
        <svg class="bi" width="16" height="16" fill="currentColor">
          <use xlink:href="~/bootstrap-icons/bootstrap-icons.svg#info-circle"/>
        </svg>
      </router-link>
    </td>
    <td class="text-end">{{ id }}</td>
    <td>{{ type }}</td>
    <td>{{ source }}</td>
    <td>{{ media_filename }}</td>
    <td class="text-center"><span class="badge" :class="statusClass">{{ status }}</span></td>
  </tr>
</template>

<script>
export default {
  name: 'JobRow',
  props: [
    'id',
    'type',
    'speechpad_job_id',
    'media_filename',
    'media_url',
    'data',
    'speechpad_media_id',
    'status',
    'workflow',
    'failure_details',
  ],
  computed: {
    source() {
      if (this.speechpad_media_id) {
        return 'A' + this.speechpad_media_id;
      }
      if (this.speechpad_job_id) {
        return 'J' + this.speechpad_job_id;
      }
      return 'N/A';
    },
    statusClass() {
      if (this.status === 'queued') {
        return 'text-bg-secondary';
      }
      if (this.status === 'in_progress') {
        return 'text-bg-primary';
      }
      if (this.status === 'completed') {
        return 'text-bg-success';
      }
      if (this.status === 'failed') {
        return 'text-bg-danger';
      }
      if (this.status === 'terminated') {
        return 'text-bg-dark';
      }
      return 'text-bg-light';
    },
  },
  methods: {},
}
</script>

<style scoped>

</style>
