import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import jobs from './modules/jobs';
import notes from './modules/notes';
import users from './modules/users';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    jobs,
    notes,
    users,
  },
  plugins: [createPersistedState()],
});
