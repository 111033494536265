<template>
  <section v-if="job">
    <h1>{{ job.Type }} Job #{{ job.ID }}</h1>
    <hr>

    <div class="container mb-3">
      <div class="row">
        <div v-if="notCompleted && job.Failure_Details" class="col-lg-6">
          <div class="alert alert-danger">
            <h4>Failure details</h4>

            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-3 fw-semibold">Time</div>
                <div class="col-auto">{{ formatDate(job.Failure_Details.time) }}</div>
              </div>
              <div v-if="job.Failure_Details.task_id" class="row align-items-center">
                <div class="col-md-3 fw-semibold">Task ID</div>
                <div class="col-auto">{{ job.Failure_Details.task_id }}</div>
              </div>
              <div v-if="job.Failure_Details.task_type" class="row align-items-center">
                <div class="col-md-3 fw-semibold">Task Type</div>
                <div class="col-auto">{{ job.Failure_Details.task_type }}</div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-3 fw-semibold">Location</div>
                <div class="col-auto">{{ job.Failure_Details.location }}</div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-3 fw-semibold">Line</div>
                <div class="col-auto">{{ job.Failure_Details.line }}</div>
              </div>
              <div class="row align-items-center mt-3">
                <hr>
                <h5>{{ job.Failure_Details.type }}</h5>
                <pre>{{ job.Failure_Details.message }}</pre>
              </div>
              <div v-if="job.Failure_Details['stack trace']" class="row align-items-center mt-2">
                <hr>
                <h5>Stack trace</h5>
                <pre>{{ job.Failure_Details['stack trace'] }}</pre>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <h5 class="card-header">Details</h5>

            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-3 fw-semibold">Status</div>
                <div class="col-auto">
                  <span class="d-flex badge align-items-center" :class="statusClass(job.Status)">
                    {{ job.Status }}
                    <span v-if="inProgress" class="spinner-border spinner-border-sm ms-1" aria-hidden="true"></span>
                  </span>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-3 fw-semibold">Media Source</div>
                <div class="col-auto">{{ source }}</div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-3 fw-semibold">Filename</div>
                <div class="col-auto">{{ job.Media_Filename }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="job.Text" class="container mb-3">
      <h2>Transcription</h2>

      <div class="card">
        <div class="card-body">
          <pre style="white-space: pre-wrap; max-height: 500px">{{ job.Text }}</pre>
        </div>
      </div>
    </div>

    <div v-if="job.Workflow" class="container mb-3">
      <h2>Tasks</h2>

      <table class="table table-striped">
        <thead class="table-dark">
        <tr>
          <th>ID</th>
          <th>Order</th>
          <th>Type</th>
          <th>Status</th>
          <th>Created</th>
          <th>Started</th>
          <th>Completed</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="task in job.Tasks" :key="task.id">
          <td>{{ task.id }}</td>
          <td>{{ task.task_order }}</td>
          <td>{{ task.type }}</td>
          <td><span class="badge" :class="statusClass(task.status)">{{ task.status }}</span></td>
          <td>{{ formatDate(task.create_time) }}</td>
          <td>{{ formatDate(task.start_time) }}</td>
          <td>{{ formatDate(task.completion_time) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>

  <section v-else-if="error">
    <div class="container">
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <svg class="bi  me-2" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <use xlink:href="~/bootstrap-icons/bootstrap-icons.svg#exclamation-octagon-fill"/>
        </svg>
        {{ error }}
      </div>
    </div>
  </section>

  <section v-else aria-hidden="true">
    <h1 class="placeholder-glow">
      <span class="placeholder col-4"></span>
    </h1>
    <div class="placeholder-glow">
      <div class="row">
        <span class="placeholder col-3"></span>
      </div>
    </div>

    <h2 class="placeholder-glow">
      <span class="placeholder col-1"></span>
    </h2>
  </section>
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";

export default {
  name: 'JobView',
  data: () => ({
    job: null,
    error: null,
  }),
  created() {
    this.loadJob();
  },
  methods: {
    ...mapActions({getJob: 'getJob'}),

    loadJob() {
      this.getJob(this.jobId)
          .then((job) => {
            this.job = job;
          })
          .catch((e) => {
            this.error = "Unable to load the job";
            if (e.response && e.response.data) {
              this.error += ": " + e.response.data.detail;
            }
          })
          .finally(() => {
            if (this.job && this.inProgress) {
              setTimeout(() => {
                this.loadJob()
              }, 2500)
            }
          });
    },

    statusClass(status) {
      if (status === 'queued') {
        return 'text-bg-secondary';
      }
      if (status === 'in_progress') {
        return 'text-bg-primary';
      }
      if (status === 'completed') {
        return 'text-bg-success';
      }
      if (status === 'failed') {
        return 'text-bg-danger';
      }
      if (status === 'terminated') {
        return 'text-bg-dark';
      }
      return 'text-bg-light';
    },

    formatDate(date) {
      if (!date) {
        return '';
      }
      let d = moment.utc(date).local();
      return d.format('M/D/YY h:mm A')
    },
  },
  computed: {
    source() {
      if (this.job.Speechpad_Media_ID) {
        return 'A' + this.job.Speechpad_Media_ID;
      }
      if (this.job.Speechpad_Job_ID) {
        return 'J' + this.job.Speechpad_Job_ID;
      }
      return 'N/A';
    },

    jobId() {
      return this.$route.params.id;
    },

    notCompleted() {
      return this.job.Status !== 'completed';
    },

    inProgress() {
      return this.job.Status === 'in_progress' || this.job.Status === 'queued';
    },
  }
}
</script>