<template>
  <section>
    <h1>Jobs</h1>
    <hr>

    <table class="table table-striped">
      <caption v-if="!jobs.length">No jobs were submitted so far...</caption>

      <thead class="table-dark">
      <tr>
        <th class="text-center"/>
        <th class="text-center">Id</th>
        <th class="text-center">Type</th>
        <th class="text-center">Media Source</th>
        <th class="text-center">Filename</th>
        <th class="text-center">Status</th>
      </tr>
      </thead>

      <tbody class="table-group-divider">
      <JobRow v-for="job in jobs" :key="job.id" v-bind="job"/>
      </tbody>
    </table>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import {defineComponent} from 'vue';
import JobRow from "@/components/JobRow.vue";

export default defineComponent({
  name: 'JobsView',
  components: {JobRow},
  created: function () {
    return this.$store.dispatch('getJobs');
  },
  computed: {
    ...mapGetters({jobs: 'stateJobs'}),
  }
})
</script>
