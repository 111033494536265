import axios from 'axios';
import store from "@/store";
import router from "@/router";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:5000';  // the FastAPI backend

axios.interceptors.response.use(undefined, function (error) {
  if (!error) {
    return Promise.reject({code: 'UNKNOWN_ERROR'});
  }
  if (error.response) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch('logOut');
      return router.push({name: 'login'});
    }
  }
  return Promise.reject(error);
});

export default axios;
