import $http from '@/http'

const state = () => ({
    jobs: null,
})

const getters = {
    stateJobs: state => state.jobs,
}

const actions = {
    async getJobs({commit}) {
        try {
            let {data: jobs} = await $http.get('jobs');
            commit('setJobs', jobs);
        } catch (e) {
            console.error(e);
        }
    },

    async getTasks(_, job_id) {
        let {data: tasks} = await $http.get(`tasks?parent_job_id=${job_id}`);
        for (let i in tasks) {
            if (typeof tasks[i].failure_details === 'string') {
                tasks[i].failure_details = JSON.parse(tasks[i].failure_details)
            }
        }
        return tasks;
    },

    async getTranscription(_, job_id) {
        let {data: transcript} = await $http.get(`job/${job_id}/transcript`);
        return transcript;
    },

    async getJob({dispatch}, job_id) {
        let {data: job} = await $http.get(`job/${job_id}`);
        if (typeof job.Workflow === 'string') {
            job.Workflow = JSON.parse(job.Workflow)
        }
        if (typeof job.Data === 'string') {
            job.Data = JSON.parse(job.Data)
        }
        if (typeof job.Failure_Details === 'string') {
            job.Failure_Details = JSON.parse(job.Failure_Details)
        }

        job.Tasks = await dispatch('getTasks', job_id);

        if (job.Status === 'completed') {
            let transcript = await dispatch('getTranscription', job_id);
            job.Text = transcript.content.text;
        }
        return job;
    },
}

const mutations = {
    setJobs(state, jobs) {
        state.jobs = jobs;
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
